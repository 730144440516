<template>
    <div class="pc-container">
        <BACK />
        <div class="form-box">
            <div class="form-title">{{type == 0 ? $t('Account.SetTransactionPassword') : $t('Account.ResetTransactionPassword')}}</div>
            <!-- <div class="warning-tips">
                <i class="el-icon-warning"></i>
                {{$t('Account.ChangeTransactionPasswordTips')}}
            </div> -->
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item prop="password">
                    <el-input v-model="form.password" show-password :placeholder="$t('Account.EnterLoginPassword')"></el-input>
                </el-form-item>
                <el-form-item prop="pay_pass">
                    <el-input v-model="form.pay_pass" show-password :placeholder="$t('Account.NewPassword')"></el-input>
                </el-form-item>
                <el-form-item prop="confirmPassword">
                    <el-input v-model="form.confirmPassword" show-password :placeholder="$t('Account.ConfirmPassword')"></el-input>
                </el-form-item>
            </el-form>
            <el-button :loading="loading" class="submit-btn" @click="submit">{{$t('Common.Confirm')}}</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            BACK: () => import('@/components/back.vue')
        },
        data() {
            const checkPassword = (rule, value, callback) => {
                if (!value) {
                    callback(new Error(this.$t('Account.EnterConfirmPassword')))
                } else if (value != this.form.pay_pass) {
                    callback(new Error(this.$t('Account.EnterConfirmPasswordError')))
                } else {
                    callback()
                }
            }
            return {
                form: {
                    password: '',
                    pay_pass: '',
                    confirmPassword: ''
                },
                rules: {
                    password: [{
                            required: true,
                            message: this.$t('Account.PleaseEnterLoginPassword'),
                            trigger: 'blur'
                        },
                        {
                            min: 6,
                            max: 20,
                            message: this.$t('Account.EnterPasswordError'),
                            trigger: 'blur'
                        }
                    ],
                    pay_pass: [{
                            required: true,
                            message: this.$t('Account.EnterNewPassword'),
                            trigger: 'blur'
                        },
                        {
                            min: 6,
                            max: 20,
                            message: this.$t('Account.EnterPasswordError'),
                            trigger: 'blur'
                        }
                    ],
                    confirmPassword: [{
                        validator: checkPassword,
                        trigger: 'blur'
                    }]
                },
                loading: false
            }
        },
        computed: {
            type() {
                return Number(this.$route.params.type)
            }
        },
        methods: {
            submit() {
                this.$refs.form.validate((valid) => {
                    // console.log(valid)
                    if(valid) {
                        this.loading = true
                        this.$api.set_pay_pass(this.form).then(res => {
                            this.loading = false
                            this.$message({
                                message: res.message,
                                type: 'success',
                                duration: 1500
                            })
                            setTimeout(() => {
                                this.$router.go(-1)
                            },1000)
                        }).catch(err => {
                            // console.log(err)
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        width: 100%;
        box-sizing: border-box;
        padding: 25px $box-padding 50px;
        background-color: #f6f6f6;

        .form-box {
            width: 600px;
            box-sizing: border-box;
            padding: 40px 40px 50px;
            margin: 0 auto;
            border-radius: 26px;
            background-color: #FFFFFF;

            .form-title {
                width: 100%;
                margin-bottom: 32px;
                font-size: 20px;
                font-weight: bold;
                color: #000000;
                text-align: center;
                word-wrap: break-word;
            }

            .warning-tips {
                display: flex;
                align-items: center;
                width: 100%;
                box-sizing: border-box;
                padding: 20px 25px;
                margin-bottom: 24px;
                border-radius: 100px;
                font-size: 15px;
                color: #f28e3d;
                word-wrap: break-word;
                background-color: #fffae6;

                .el-icon-warning {
                    margin-right: 6px;
                    font-size: 20px;
                    color: #f28e3d;
                    white-space: nowrap;
                }
            }

            /deep/ .el-form-item {
                margin-bottom: 24px;
            }

            /deep/ .el-input__inner {
                height: 50px;
                line-height: 50px;
                padding: 0 25px;
                border-radius: 50px;
                border: 1px solid #D3D3D3;
                font-size: 15px;
                color: #000000;
                background-color: #FFFFFF;
            }

            .submit-btn {
                width: 100%;
                height: 56px;
                box-sizing: border-box;
                margin: 36px 0 0;
                padding: 0;
                border: 0;
                border-radius: 56px;
                font-size: 15px;
                color: #FFFFFF;
                text-align: center;
                background-color: #F08834;
                cursor: pointer;
            }
        }
    }
</style>
